import React from "react";
import { AccordionItem } from "types/widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";

type Props = {
  item: AccordionItem,
  expanded?: boolean,
  onExpandedChange: (id: number) => void
}
type State = {}

class SingleAccordionItem extends React.Component<Props, State> {
  state: State = {};

  toggleExpand = () => {
    const { item, onExpandedChange } = this.props;
    onExpandedChange(item.id);
  };

  render = () => {
    const { item, expanded } = this.props;
    const {} = this.state;

    return (
      <div className={"p-4 border"}>
        <div className={"text-lg text-gray-500 hover:text-gray-900 cursor-pointer"} onClick={this.toggleExpand}>
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} size={"xs"} />
          <span className={"pl-2"}>
                        {item.title}
                    </span>
        </div>
        <div className={`text-gray-600 ${expanded || "hidden"}`}>
          {item.content}
        </div>
      </div>
    );
  };
}

export default SingleAccordionItem;
