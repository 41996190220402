import React from "react";
import Link from "navigation/Link";

type Props = {}
type State = {}

class Nav extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    const {} = this.props;
    const {} = this.state;

    return (
      <nav className={"border"}>
        <ul className={"list-none"}>
          <Link href={"/"}>Songs</Link>
          <Link href={"/location"}>Location</Link>
          <Link href={"/comments"}>Comments</Link>
          <Link href={"/images"}>Images</Link>
          <Link href={"/videos"}>Videos</Link>
          <Link href={"/widgets"}>Widgets</Link>
        </ul>
      </nav>
    );
  };
}

export default Nav;
