import React from "react";
import java from "api/java";

import { Image } from "types/images";
import SearchBar from "search/SearchBar";
import ImageList from "images/ImageList";

type Props = {}
type State = {
  imageList: Image[]
}

class Images extends React.Component<Props, State> {
  state: State = {
    imageList: []
  };

  onImageSearchSubmit = (searchText: string) => {
    java.post("/unsplash/photos", {
      query: searchText,
      per_page: 12
    }).then((response) => {
      this.setState({ imageList: response.data.results });
    }).catch((error) => {
      console.error(error);
      this.setState({ imageList: [] });
    });
  };

  render = () => {
    const {} = this.props;
    const {} = this.state;

    return (
      <div className={"flex flex-row"}>
        <div className={"w-full border"}>
          <SearchBar
            labelText={"Image search"}
            defaultValue={"car"}
            onSubmit={this.onImageSearchSubmit}
          />
          <ImageList imageList={this.state.imageList} />
        </div>
      </div>
    );
  };
}

export default Images;
