import React from "react";

interface CommentProps {
  author: string,
  timeAgo: string,
  content: string,
  avatar: string
}

class Comment extends React.Component<CommentProps> {
  render = () => {
    return (
      <div className="flex p-2 m-2 border rounded w-auto">
        <div className="flex-none w-14 h-14 relative">
          <img src={this.props.avatar} alt={"avatar"}
               className="absolute inset-0 w-full h-full object-cover rounded-lg" />
        </div>
        <div className="flex flex-col pl-4">
          <div className="flex flex-row items-baseline">
            <div className="text-xl leading-7 font-bold">
              {this.props.author}
            </div>
            <div className="text-sm font-medium text-gray-400 ml-3">
              {this.props.timeAgo}
            </div>
          </div>
          <div className="text-md">
            {this.props.content}
          </div>
        </div>
      </div>
    );
  };
}

export default Comment;
