import React from "react";
import { Image } from "types/images";
import ImageCard from "images/ImageCard";

type Props = {
  imageList: Array<Image>
}
type State = {}

class ImageList extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    let imageList = this.props.imageList.map((image) => (
      <ImageCard key={image.id} image={image} />
    ));

    return (
      <div className={"grid grid-cols-3"} style={{ gridAutoRows: "1px" }}>
        {imageList}
      </div>
    );
  };
}

export default ImageList;
