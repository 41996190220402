import React from "react";
import { Video } from "types/videos";

type Props = {
  video: Video | null
}
type State = {}

class VideoDetail extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    const { video } = this.props;
    if (!video) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    return (
      <div className={"p-4"}>
        <div className={"aspect-w-16 aspect-h-9"}>
          <iframe src={`https://www.youtube.com/embed/${video.id.videoId}`}
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen>
          </iframe>
        </div>
        <div className={"bg-white p-4 flex flex-col justify-between leading-normal border h-48"}>
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            {video.snippet.title}
          </div>
          <div className="text-gray-700 text-sm">{video.snippet.description}</div>
          <div className="text-gray-400 text-xs">{new Date(video.snippet.publishedAt).toDateString()}</div>
        </div>
      </div>
    );
  };
}

export default VideoDetail;
