import React from "react";
import Dropdown from "widgets/Dropdown";
import { DropdownOptions } from "types/widgets";

type Props = {}
type State = {
  text: string
}

class Translate extends React.Component<Props, State> {
  state: State = {
    text: ""
  };

  options: DropdownOptions[] = [
    { value: "af", label: "Afrikaans" },
    { value: "ar", label: "Arabic" },
    { value: "hi", label: "Hindi" }
  ];

  render = () => {
    const {} = this.props;
    const { text } = this.state;

    return (
      <div className={"flex flex-row"}>
        <div className={"flex flex-col p-8"}>
          <label>Enter text to translate</label>
          <input
            className={"p-2 border w-64"}
            value={text}
            onChange={(e) => {
              this.setState({ text: e.target.value });
            }}
          />
        </div>
        <Dropdown
          label={"Select a language"}
          options={this.options}
        />
      </div>
    );
  };
}

export default Translate;
