import React from "react";

type Props = {
  lat: number
}
type State = {}

class Season extends React.Component<Props, State> {
  getSeason = (lat: number, month: number) => {
    if (month > 2 && month < 9) {
      return lat > 0 ? "summer" : "winter";
    } else {
      return lat > 0 ? "winter" : "summer";
    }
  };

  render = () => {
    let season = this.getSeason(this.props.lat, new Date().getMonth());
    let text = season === "winter" ? "Burr! It is chilly" : "Lets hit the beach";

    return (
      <div className={"text-lg p-2"}>
        {text}
      </div>
    );
  };
}

export default Season;
