import React from "react";
import Season from "location/Season";

type Props = {};
type State = {
  lat: number | null,
  long: number | null,
  errorMessage: string
};

class Location extends React.Component<Props, State> {
  state: State = {
    lat: null,
    long: null,
    errorMessage: ""
  };

  componentDidMount = () => {
    window.navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        lat: position.coords.latitude,
        long: position.coords.longitude
      });
    }, (err) => {
      this.setState({
        lat: null,
        long: null,
        errorMessage: err.message
      });
      console.error(err);
    });
  };

  render = () => {
    if (this.state.errorMessage && !this.state.lat && !this.state.long) {
      return <div className={"text-red-500"}>Error: {this.state.errorMessage}</div>;
    }
    if (!this.state.errorMessage && this.state.lat && this.state.long) {
      return (
        <div className={"text-lg"}>
          <div className={"pl-2 pt-2"}>Latitude: {this.state.lat}</div>
          <div className={"pl-2"}>Longitude: {this.state.long}</div>
          <Season lat={this.state.lat} />
        </div>
      );
    }
    return (
      <div>Loading!</div>
    );
  };
}

export default Location;
