import React from "react";
import { Video } from "types/videos";
import VideoItem from "videos/VideoItem";

type Props = {
  videos: Video[],
  onVideoSelect: Function
}
type State = {}

class VideoList extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    const { videos, onVideoSelect } = this.props;
    const renderedList = videos.map((video) => {
      return (
        <VideoItem
          key={video.id.videoId}
          video={video}
          onVideoSelect={onVideoSelect}
        />
      );
    });

    return (
      <div className={"container flex flex-col justify-items-end"}>
        {renderedList}
      </div>
    );
  };
}

export default VideoList;
