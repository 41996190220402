import React from "react";

type Props = {}
type State = {}

class Songs extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    const {} = this.props;
    const {} = this.state;

    return (
      <div>
        Songs
      </div>
    );
  };
}

export default Songs;
