import React from "react";

type Props = {
  path: string
}
type State = {
  currentPath: string;
}

class Route extends React.Component<Props, State> {
  state: State = {
    currentPath: window.location.pathname
  };

  onPathChange = () => {
    this.setState({ currentPath: window.location.pathname });
  };

  componentDidMount = () => {
    window.addEventListener("popstate", this.onPathChange);
  };

  componentWillUnmount = () => {
    window.removeEventListener("popstate", this.onPathChange);
  };

  render = () => {
    const { path, children } = this.props;
    const { currentPath } = this.state;

    if (currentPath === path) {
      return children;
    }
    return null;
  };
}

export default Route;
