import React from "react";

import Widgets from "widgets/Widgets";
import Videos from "videos/Videos";
import Images from "images/Images";
import Route from "navigation/Route";
import Nav from "navigation/Nav";
import Location from "location/Location";
import CommentList from "comments/CommentList";
import Songs from "songs/Songs";

type Props = {};
type State = {};

class App extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    return (
      <div className={"container m-auto flex flex-col"}>
        <Nav />
        <Route path={"/"}>
          <Songs />
        </Route>
        <Route path={"/location"}>
          <Location />
        </Route>
        <Route path={"/comments"}>
          <CommentList />
        </Route>
        <Route path={"/images"}>
          <Images />
        </Route>
        <Route path={"/videos"}>
          <Videos />
        </Route>
        <Route path={"/widgets"}>
          <Widgets />
        </Route>
      </div>
    );
  };
}

export default App;
