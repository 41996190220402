import React, { ChangeEvent } from "react";
import { DropdownOptions } from "types/widgets";

type Props = {
  label?: string,
  selected?: string,
  options: DropdownOptions[]
}
type State = {
  selected: string
}

class Dropdown extends React.Component<Props, State> {
  state: State = {
    selected: this.props.selected || ""
  };

  onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selected: event.target.value });
  };

  render = () => {
    const { label, options } = this.props;
    const { selected } = this.state;

    const renderedOption = options.map(option => {
      return (
        <option
          key={option.label}
          label={option.label}
          value={option.value}
        />
      );
    });

    return (
      <div className={"p-8 flex flex-col"}>
        <label>{label || "Dropdown"}</label>
        <div>
          <select
            className={"p-2 border w-64"}
            value={selected}
            onChange={this.onSelectChange}>
            {renderedOption}
          </select>
        </div>
      </div>
    );
  }
  ;
}

export default Dropdown;
