import React from "react";
import { Video } from "types/videos";

type Props = {
  video: Video,
  onVideoSelect: Function
}
type State = {}

class VideoItem extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    const { video, onVideoSelect } = this.props;

    return (
      <div
        onClick={() => {
          onVideoSelect(video);
        }}
        className="justify-self-end"
      >
        <div className="w-full p-3 cursor-pointer">
          <div
            className="flex flex-row rounded overflow-hidden h-32 border shadow shadow-lg">
            <img className="block h-auto w-full lg:w-48 flex-none bg-cover h-24"
                 src={video.snippet.thumbnails.medium.url}
                 alt={video.snippet.description} />
            <div className="bg-white p-4 flex flex-col justify-between leading-normal">
              <div className="text-black font-bold text-xl mb-2 leading-tight truncate">
                {video.snippet.title}
              </div>
              <span className="text-gray-700 text-sm overflow-ellipsis">{video.snippet.description}</span>
              <span className="text-gray-400 text-xs">{new Date(video.snippet.publishedAt).toDateString()}
                            </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default VideoItem;
