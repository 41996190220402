import React, { ChangeEvent, FormEvent } from "react";

type Props = {
  labelText?: string,
  defaultValue?: string,
  onSubmit?: (searchText: string) => void,
  onChange?: (searchText: string) => void
}
type State = {
  searchText: string
}

class SearchBar extends React.Component<Props, State> {
  state: State = {
    searchText: this.props.defaultValue || ""
  };

  componentDidMount = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.searchText);
    }
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.searchText);
    }
  };

  onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.searchText);
      }
    });
  };

  render = () => {
    return (
      <div className={"flex justify-center p-8 w-full"}>
        <form onSubmit={this.onFormSubmit} className={"w-full"}>
          <label htmlFor={"search"}>{this.props.labelText || "Search"}</label>
          <input
            className={"w-full h-10 p-2 border-2 rounded"}
            type={"text"}
            autoComplete={"off"}
            value={this.state.searchText}
            onChange={this.onValueChange}
          />
        </form>
      </div>
    );
  };
}

export default SearchBar;
