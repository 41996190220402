import React from "react";
import SearchBar from "search/SearchBar";
import java from "api/java";
import { AxiosResponse } from "axios";
import { WikiResponse, WikiSearchItem } from "types/widgets";

type Props = {}
type State = {
  searchText: string,
  resultList: WikiSearchItem[]
}

class WikiSearch extends React.Component<Props, State> {
  state: State = {
    searchText: "react js",
    resultList: []
  };
  timeoutId: any;

  onSearchChange = (searchText: string) => {
    this.setState({ searchText: searchText });
  };

  onSearchSubmit = (searchText: string) => {
    this.setState({ searchText: searchText });
  };

  fetchData = () => {
    if (this.state.searchText === "") {
      this.setState({ resultList: [] });
      return;
    }
    java.post("/wiki/search", {
      srsearch: this.state.searchText
    }).then((response: AxiosResponse<WikiResponse>) => {
      this.setState({ resultList: response.data.query.search });
    }).catch((error) => {
      this.setState({ resultList: [] });
      console.error(error);
    });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
    if (prevState.searchText !== this.state.searchText) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(this.fetchData, 200);
    }
  };

  render = () => {
    const {} = this.props;
    const { searchText, resultList } = this.state;

    const renderedResultList = resultList.map(result => {
      return (
        <div key={result.pageid} className={"p-2 border"}>
          <div className={"text-xl"}>
            <a className={"hover:text-blue-500"}
               href={`https://en.wikipedia.org?curid=${result.pageid}`} target={"_blank"}>
              {result.title}
            </a>
          </div>
          <span dangerouslySetInnerHTML={{ __html: result.snippet }} />
        </div>
      );
    });

    return (
      <div className={"border"}>
        <SearchBar
          labelText={"Wiki search"}
          defaultValue={searchText}
          onChange={this.onSearchChange}
          onSubmit={this.onSearchSubmit}
        />
        <div className={"px-8 pb-8"}>
          {renderedResultList}
        </div>
      </div>
    );
  };
}

export default WikiSearch;
