import React from "react";

type Props = {
  href: string
}
type State = {}

class Link extends React.Component<Props, State> {
  state: State = {};

  onAnchorClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    event.preventDefault();
    window.history.pushState({}, "", this.props.href);

    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  render = () => {
    const { href, children } = this.props;
    const {} = this.state;

    return (
      <li className={"inline-block p-4"}>
        <a href={href}
           className={"no-underline"}
           onClick={this.onAnchorClick}
        >
          {children}
        </a>
      </li>
    );
  };
}

export default Link;
