import React from "react";
import { AccordionItem } from "types/widgets";
import SingleAccordionItem from "widgets/SingleAccordionItem";

type Props = {
  items: AccordionItem[]
}
type State = {
  expandedItem: number
}

class Accordion extends React.Component<Props, State> {
  state: State = {
    expandedItem: 0
  };

  onExpandedChange = (id: number) => {
    this.setState({ expandedItem: id });
  };

  render = () => {
    const { items } = this.props;
    const { expandedItem } = this.state;

    const renderedItems = items.map((item) => {
      return (
        <SingleAccordionItem
          key={item.id}
          item={item}
          expanded={expandedItem === item.id}
          onExpandedChange={this.onExpandedChange}
        />
      );
    });

    return (
      <div>
        {renderedItems}
      </div>
    );
  };
}

export default Accordion;
