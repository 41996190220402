import React from "react";
import youtube from "api/youtube";

import { Video } from "types/videos";
import SearchBar from "search/SearchBar";
import VideoDetail from "videos/VideoDetail";
import VideoList from "videos/VideoList";

type Props = {}
type State = {
  videoList: Video[],
  selectedVideo: Video | null
}

class Videos extends React.Component<Props, State> {
  state: State = {
    videoList: [],
    selectedVideo: null
  };

  onVideoSearchSubmit = (searchText: string) => {
    youtube.get("/search", {
      params: {
        q: searchText
      }
    }).then((response) => {
      if (response.data.items.length > 0) {
        this.setState({
          videoList: response.data.items,
          selectedVideo: response.data.items[0]
        });
      } else {
        this.setState({ videoList: [] });
      }
    }).catch((error) => {
      console.error(error);
      this.setState({ videoList: [] });
    });
  };

  onVideoSelect = (video: Video) => {
    this.setState({ selectedVideo: video });
  };

  render = () => {
    const {} = this.props;
    const {} = this.state;

    return (
      <div className={"border"}>
        <SearchBar
          labelText={"Video search"}
          defaultValue={"xqcow"}
          onSubmit={this.onVideoSearchSubmit}
        />
        <div className={"flex flex-row"}>
          <div className={"w-3/5"}>
            <VideoDetail
              video={this.state.selectedVideo}
            />
          </div>
          <div className={"flex-grow-0 w-2/5"}>
            <VideoList
              videos={this.state.videoList}
              onVideoSelect={this.onVideoSelect}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default Videos;
