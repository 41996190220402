import React from "react";

type ApprovalCardProps = {}

class ApprovalCard extends React.Component<ApprovalCardProps> {
  render = () => {
    return (
      <div className={"flex flex-col w-96 m-4 p-2 border-2"}>
        <div>
          {this.props.children}
        </div>
        <div className={"flex flex-row"}>
          <button className={"w-1/2 h-10 m-2 flex items-center justify-center rounded bg-green-300"}>
            Approve
          </button>
          <button className={"w-1/2 h-10 m-2 flex items-center justify-center rounded bg-red-300"}>
            Reject
          </button>
        </div>
      </div>
    );
  };
}

export default ApprovalCard;
