import React from "react";
import { Image } from "types/images";

type Props = {
  image: Image
}
type State = {
  spans: number
}

class ImageCard extends React.Component<Props, State> {
  state: State = {
    spans: 0
  };
  imageRef: React.RefObject<HTMLImageElement>;

  constructor(props: Props) {
    super(props);
    this.imageRef = React.createRef();
  }

  setSpans = () => {
    const height = this.imageRef.current?.clientHeight || 0;
    this.setState({ spans: height });
  };

  componentDidMount() {
    this.imageRef.current?.addEventListener("load", this.setSpans);
  }

  render = () => {
    const { description, urls } = this.props.image;

    return (
      <div style={{ gridRowEnd: `span ${this.state.spans}` }}>
        <img ref={this.imageRef}
             className={"p-4 rounded-md"}
             src={urls.regular}
             alt={description}
        />
      </div>
    );
  };
}

export default ImageCard;
