import React from "react";
import Accordion from "widgets/Accordion";
import WikiSearch from "widgets/WikiSearch";
import { AccordionItem } from "types/widgets";
import Translate from "widgets/Translate";

type Props = {}
type State = {}

class Widgets extends React.Component<Props, State> {
  state: State = {};

  items: AccordionItem[] = [
    {
      id: 1,
      title: "What is React?",
      content: "React is a front end javascript framework"
    },
    {
      id: 2,
      title: "Why use React?",
      content: "React is a favorite JS library among engineers"
    },
    {
      id: 3,
      title: "How do you use React?",
      content: "You use React by creating components"
    }
  ];

  render = () => {
    const {} = this.props;
    const {} = this.state;

    return (
      <div className={"border"}>
        <Accordion items={this.items} />
        <WikiSearch />
        <Translate />
      </div>
    );
  };
}

export default Widgets;
