import React from "react";
import faker from "faker";

import ApprovalCard from "comments/ApprovalCard";
import Comment from "comments/Comment";

type Props = {}
type State = {}

class CommentList extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    return (
      <div className={"flex flex-row"}>
        <div>
          <ApprovalCard>
            <Comment
              author={"Sam"}
              timeAgo={"Today at 6:01 PM"}
              content={"Nice content"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"Alex"}
              timeAgo={"Today at 7:10 PM"}
              content={"Awesome"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"Jane"}
              timeAgo={"Today at 8:30 PM"}
              content={"Not that good"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
        </div>
        <div>
          <ApprovalCard>
            <Comment
              author={"Joe"}
              timeAgo={"Today at 8:50 PM"}
              content={"Ok I guess"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"John"}
              timeAgo={"Today at 8:53 PM"}
              content={"Keep it up"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"Rachel"}
              timeAgo={"Today at 9:02 PM"}
              content={"Yeah right!"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
        </div>
        <div>
          <ApprovalCard>
            <Comment
              author={"Sam"}
              timeAgo={"Today at 6:01 PM"}
              content={"Nice content"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"Alex"}
              timeAgo={"Today at 7:10 PM"}
              content={"Awesome"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"Jane"}
              timeAgo={"Today at 8:30 PM"}
              content={"Not that good"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
        </div>
        <div>
          <ApprovalCard>
            <Comment
              author={"Joe"}
              timeAgo={"Today at 8:50 PM"}
              content={"Ok I guess"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"John"}
              timeAgo={"Today at 8:53 PM"}
              content={"Keep it up"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
          <ApprovalCard>
            <Comment
              author={"Rachel"}
              timeAgo={"Today at 9:02 PM"}
              content={"Yeah right!"}
              avatar={faker.image.avatar()}
            />
          </ApprovalCard>
        </div>
      </div>
    );
  };
}

export default CommentList;
